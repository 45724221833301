import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Container, IconButton, Tooltip } from '@mui/material';
import { ContentCopy, ThumbUp, ThumbDown } from '@mui/icons-material';
import axios from 'axios';
import { OppUUIDComponentProps } from './Interfaces';

interface SmsGenResponse {
  status: string;
  message: string;
  data: {
    session_id: string;
    api_responses: [];
    previous_conversations: [];
    opportunity_info: {};
    job_info: {};
    message: string;
    response_message: string;
  };
}

interface LogResponse {
  status: string;
}

interface ApiDataBody {
  opportunity_uuid: string;
  session_id?: string;
  partner?: string;
  opportunity_type?: string;
  bgc_passed_date?: string;
  activation_date?: string;
  first_delivery_date?: string;
  last_delivery_date?: string;
}

const OppUUIDComponent: React.FC<OppUUIDComponentProps> = (props) => {
  const [isThumbsUpActiveResp1, setIsThumbsUpActiveResp1] = useState(false);
  const [isThumbsDownActiveResp1, setIsThumbsDownActiveResp1] = useState(false);
  const [qaCommentsResp1, setQaCommentsResp1] = useState('');
  const [apiResponseStatus1, setApiResponseStatus1] = useState<string | null>(null);
  const [option1, setOption1] = useState('');
  const [session_id, setSessionId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showComment1, setShowComment1] = useState(false);
  const { opportunity_uuid } = props;

  const admins = [
    'kai.williams@getscale.com',
    'michael.griffin@getscale.com',
    'ness.artoul@getscale.com',
    'kai.williams@getsales.team',
    'michael.griffin@getsales.team',
    'ness.artoul@getsales.team',
  ];
  const qa_users = [
    // 'stefan.nieth@getsales.team',
    // 'stefan.nieth@getscale.com',
    // 'maricruz.rojas@getsales.team',
    // 'maricruz.rojas@getscale.com',
    // 'farid@getsales.team',
    // 'farid@getscale.com',
    // 'jonathan.hibbard@getsales.team',
    // 'jonathan.hibbard@getscale.com',
    // 'connor.powell@getsales.team',
    // 'connor.powell@getscale.com',
    // 'tom@getsales.team',
    // 'tom@getscale.com',
    // 'josh@getsales.team',
    // 'josh@getscale.com',
    ''
  ];

  const getEnv = () => {
    let env: string;
    if (admins.includes(props?.agent || '')) {
      env = 'dev';
    } else if (qa_users.includes(props?.agent || '')) {
      env = 'qa'
    } else {
      env = 'prod'
    }
    return env
  }

  const env = getEnv();

  useEffect(() => {
    // Generate initial response on page load
    if (opportunity_uuid) {
      handleGenerate();
    }
  }, [opportunity_uuid]);

  const generateSessionId = () => {
    return `session-${Math.random().toString(36).substr(2, 9)}`;
  };

  const handleThumbsUpClick = (responseNum: number) => {
    const currentOptionText = option1

    console.log('setting active')
    setIsThumbsUpActiveResp1(!isThumbsUpActiveResp1);
    setIsThumbsDownActiveResp1(false)
    // Auto submit the rating if it is clicked
    if (!isThumbsUpActiveResp1) {
      handleQARating(currentOptionText, 'positive')
    }
  };

  const handleThumbsDownClick = (responseNum: number) => {
    const currentOptionText = option1

    setIsThumbsDownActiveResp1(!isThumbsDownActiveResp1);
    setIsThumbsUpActiveResp1(false)
    // Auto submit the rating if from a rep and it stays selected for 2 seconds
    if (env === 'prod') {
        if (!isThumbsDownActiveResp1) {
            handleQARating(currentOptionText, 'negative')
        }
    }
  };


  const handleGenerate = async () => {
    setApiResponseStatus1(null);
    setShowComment1(false);
    setIsThumbsDownActiveResp1(false);
    setIsThumbsUpActiveResp1(false);
    setIsLoading(true);
    setError('');
    try {
      const newSessionId = generateSessionId();
      setSessionId(newSessionId);
      const request_ts = new Date();
      const event_ts = request_ts.toISOString();

      if (admins.includes(props?.agent || '')) {
        const env = 'dev';
        const request_payload = { ...props, event_ts, session_id: newSessionId, environment: env };
        const response = await axios.post<SmsGenResponse>('/api/langgraph-sms-recommendation-dev', request_payload);
        console.log('GOT RESPONSE');
        console.log(response)
        setOption1(response.data.message);
        const response_ts = new Date();
        const received_ts = response_ts.toISOString();
        const execution_time = (response_ts.getTime() - request_ts.getTime()) / 1000;
        const event_name = 'LangGraph SMS Options Received';
        const log_payload = {
          ...props,
          event_name,
          received_ts,
          execution_time,
          session_id: newSessionId,
          environment: env,
        };
        axios.post<LogResponse>('/api/create-bq-log-event', log_payload);
      } else if (qa_users.includes(props?.agent || '')) {
        const env = 'qa';
        const request_payload = { ...props, event_ts, session_id: newSessionId, environment: env };
        const response = await axios.post<SmsGenResponse>('/api/langgraph-sms-recommendation-qa', request_payload);
        setOption1(response.data.message);
        const response_ts = new Date();
        const received_ts = response_ts.toISOString();
        const execution_time = (response_ts.getTime() - request_ts.getTime()) / 1000;
        const event_name = 'LangGraph SMS Options Received';
        const log_payload = {
          ...props,
          event_name,
          received_ts,
          execution_time,
          session_id: newSessionId,
          environment: env,
        };
        axios.post<LogResponse>('/api/create-bq-log-event', log_payload);
      } else {
        const env = 'prod';
        const request_payload = { ...props, event_ts, session_id: newSessionId, environment: env };
        const response = await axios.post<SmsGenResponse>('/api/langgraph-sms-recommendation', request_payload);
        setOption1(response.data.message);
        const response_ts = new Date();
        const received_ts = response_ts.toISOString();
        const execution_time = (response_ts.getTime() - request_ts.getTime()) / 1000;
        const event_name = 'LangGraph SMS Options Received';
        const log_payload = {
          ...props,
          event_name,
          received_ts,
          execution_time,
          session_id: newSessionId,
          environment: env,
        };
        axios.post<LogResponse>('/api/create-bq-log-event', log_payload);
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        setError('Error fetching data: ' + err.message);
        console.error('Error fetching data:', err);
      } else {
        setError('An unknown error occurred');
        console.error('Unknown error:', err);
      }
    } finally {
      setIsLoading(false);
    }
  };


  const getResponseRating = (isThumbsUp: boolean, isThumbsDown: boolean): 'positive' | 'negative' | 'neutral' => {
    if (isThumbsUp) {
      return 'positive';
    } else if (isThumbsDown) {
      return 'negative';
    } else {
      return 'neutral';
    }
  };


  // This function handles sending the rating when an agent clicks the thumbs up/thumbs down, it will automatically submit
  // const handleAgentRating = (optionNumber: number, optionText: string, agentRating: 'positive' | 'negative') => {
  //   const event_ts = new Date().toISOString();
  //   const event_name = 'Agent Rating';
  //   const log_payload = {
  //     ...props,
  //     event_name,
  //     event_ts,
  //     environment: env,
  //     session_id,
  //     option_selected: optionNumber,
  //     option_text: optionText,
  //     qa_response_rating: agentRating,
  //     qa_comments: ''
  //   };
  
  //   axios.post<LogResponse>('/api/create-qa-log', log_payload)
  //     .then(response => {
  //       console.log('Agent rating submission successful:', response);
  //     })
  //     .catch(error => {
  //       console.error('Error submitting agent rating:', error);
  //     });
  // }


  // This function handles sending the rating when an agent clicks the thumbs up/thumbs down, it will automatically submit
  const handleQARating = (messageText: string, agentRating: 'positive' | 'negative') => {
    const event_ts = new Date().toISOString();
    const log_payload = {
      ...props,
      event_name: 'Sales Pilot QA Rating',
      event_ts,
      session_id,
      message: messageText,
      qa_response_rating: agentRating,
      qa_comments: ''
    };

    if (admins.includes(props?.agent || '')) {
      axios.post<LogResponse>('/api/create_qa_log_dev', log_payload)
        .then(response => {
          console.log('Agent rating submission successful:', response);
        })
        .catch(error => {
          console.error('Error submitting agent rating:', error);
        });
    } else if (qa_users.includes(props?.agent || '')) {
      axios.post<LogResponse>('/api/create_qa_log_qa', log_payload)
        .then(response => {
          console.log('Agent rating submission successful:', response);
        })
        .catch(error => {
          console.error('Error submitting agent rating:', error);
        });
    } else {
      axios.post<LogResponse>('/api/create_qa_log', log_payload)
        .then(response => {
          console.log('Agent rating submission successful:', response);
        })
        .catch(error => {
          console.error('Error submitting agent rating:', error);
        });
    }
  }


  const handleSubmit = ({
    optionNumber,
    response,
    responseRating
  }: {
    optionNumber: number;
    response: string;
    responseRating: 'positive' | 'negative' | 'neutral'
  }) => {

    if (optionNumber === 1) {
      setApiResponseStatus1(null);
    }
  
    const event_ts = new Date().toISOString();
    const log_payload = {
      ...props,
      event_name: 'Sales Pilot QA Rating',
      event_ts,
      session_id,
      message: response,
      qa_response_rating: responseRating,
      qa_comments: qaCommentsResp1
    };

    if (admins.includes(props?.agent || '')) {
      axios.post<LogResponse>('/api/create_qa_log_dev', log_payload)
        .then(response => {
          console.log('QA Submission successful:', response);
          const successMsg = 'Feedback submitted!'
          if (optionNumber === 1){
            setApiResponseStatus1(successMsg);
          }
        })
        .catch(error => {
          console.error('Error submitting QA rating:', error);
          const failureMsg = 'Feedback submission failed. Please try again.'
          if (optionNumber === 1){
            setApiResponseStatus1(failureMsg);
          }
        });
    } else if (qa_users.includes(props?.agent || '')) {
      axios.post<LogResponse>('/api/create_qa_log_qa', log_payload)
        .then(response => {
          console.log('QA Submission successful:', response);
          const successMsg = 'Feedback submitted!'
          if (optionNumber === 1){
            setApiResponseStatus1(successMsg);
          }
        })
        .catch(error => {
          console.error('Error submitting QA rating:', error);
          const failureMsg = 'Feedback submission failed. Please try again.'
          if (optionNumber === 1){
            setApiResponseStatus1(failureMsg);
          }
        });
    } else {
      axios.post<LogResponse>('/api/create_qa_log', log_payload)
        .then(response => {
          console.log('QA Submission successful:', response);
          const successMsg = 'Feedback submitted!'
          if (optionNumber === 1){
            setApiResponseStatus1(successMsg);
          }
        })
        .catch(error => {
          console.error('Error submitting QA rating:', error);
          const failureMsg = 'Feedback submission failed. Please try again.'
          if (optionNumber === 1){
            setApiResponseStatus1(failureMsg);
          }
        });
    }
  };


  const toggleCommentSection = (optionNumber: number) => {
    if (optionNumber === 1) {
      setShowComment1(!showComment1);
      if (apiResponseStatus1 && apiResponseStatus1.length > 0){
        setApiResponseStatus1(null)
      }
    }
  };


  const sendCopyEvent = ({
    optionNumber,
    text,
  }: {
    optionNumber: string;
    text: string;
  }) => {
    const event_ts = new Date().toISOString();
    const event_name = 'LangGraph SMS Recommendation Copied';
    const log_payload = {
      ...props,
      event_name,
      event_ts,
      environment: env,
      session_id,
      option_selected: optionNumber,
      option_text: text,
    };

    if (admins.includes(props?.agent || '')) {
      axios.post<LogResponse>('/api/create_qa_log_dev', log_payload);
    } else if (qa_users.includes(props?.agent || '')) {
      axios.post<LogResponse>('/api/create_qa_log_qa', log_payload);
    } else {
      axios.post<LogResponse>('/api/create_qa_log', log_payload);
    }
  };

  const fallbackCopyToClipboard = (text: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
      console.log('Copied to clipboard!');
      sendCopyEvent({ optionNumber: '1', text }); // default to 1 for now
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
    document.body.removeChild(textArea);
  };

  const copyToClipboard = (text: string, optionNumber: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('Copied to clipboard!');
        sendCopyEvent({ optionNumber, text });
      })
      .catch((err) => {
        console.error('Failed to copy using Clipboard API:', err);
        fallbackCopyToClipboard(text);
      });
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        height: '350px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        overflowY: 'auto', // This allows the content to be scrollable
      }}
    >
      <Box sx={{ position: 'relative', width: '100%', marginBottom: 2, backgroundColor: '#f5f5f5' }}>
        <TextField
          fullWidth
          label="Option 1"
          variant="outlined"
          multiline
          minRows={4}
          maxRows={10}
          InputLabelProps={{ shrink: true }}
          placeholder={'Please press Generate Response if one does not load automatically.'}
          value={option1}
          onChange={(e) => setOption1(e.target.value)}
        />
        <Button size='small' onClick={() => toggleCommentSection(1)} sx={{ marginBottom: 2 }}>
          {showComment1 ? 'Hide Comment' : 'Add Comment'}
        </Button>
        {showComment1 && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              fullWidth
              label="Feedback"
              variant="outlined"
              placeholder="Enter your comments here"
              InputLabelProps={{ shrink: true }}
              InputProps={{ sx: { height: '40px' } }} // Adjust height to make it single line
              value={qaCommentsResp1}
              onChange={(e) => setQaCommentsResp1(e.target.value)}
            />
            <Button
              variant="contained"
              size="small"
              color="primary"
              sx={{ alignSelf: 'flex-start' }}
              onClick={() =>
                handleSubmit({
                  optionNumber: 1,
                  response: option1,
                  responseRating: getResponseRating(isThumbsUpActiveResp1, isThumbsDownActiveResp1),
                })
              }
            >
              Submit
            </Button>
          </Box>
        )}
        <Box>
          {apiResponseStatus1 && <p>{apiResponseStatus1}</p>}
        </Box>


        <Box sx={{ position: 'absolute', bottom: 8, right: 8, display: 'flex', gap: 1 }}>
          <Tooltip title="Thumbs Up">
            <IconButton onClick={() => handleThumbsUpClick(1)}>
              <ThumbUp color={isThumbsUpActiveResp1 ? 'success' : 'inherit'} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Thumbs Down">
            <IconButton onClick={() => handleThumbsDownClick(1)}>
              <ThumbDown color={isThumbsDownActiveResp1 ? 'error' : 'inherit'} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Copy to clipboard">
            <IconButton onClick={() => copyToClipboard(option1, 'Option 1')}>
              <ContentCopy />
            </IconButton>
          </Tooltip>
        </Box>

    </Box>
    <Box sx={{ textAlign: 'center', width: '100%' }}>
            <Button variant="contained" color="primary" onClick={handleGenerate} disabled={isLoading} sx={{ textTransform: 'none' }}>
                {isLoading ? 'Generating...' : 'Generate Response'}
            </Button>
        </Box>
        {error && (
            <Box sx={{ textAlign: 'center', width: '100%', color: 'red' }}>
                <Typography variant="body1">{error}</Typography>
            </Box>
        )}
      
    </Container>
  );
};

export default OppUUIDComponent;
